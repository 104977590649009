import React from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {ILotterySummary} from "../../../../core/models/Lottery";
import {SummaryBlock} from "../summary";
import {useTranslation} from "react-i18next";

export default React.memo(function BlSummury({summary}: IProps) {
    const {t} = useTranslation();

    return (
        <Typography as="div" className="lottery-countdown-container mt-0">
            <Typography as="div" className="summury">
                {summary && _.isNil(summary.level_cnt) && (
                    <SummaryBlock title={t("Шансы")} value={summary.personal_cnt} />
                )}
                {summary && !_.isNil(summary.level_cnt) && (
                    <>
                        <SummaryBlock title={t("Личные шансы")} value={summary.personal_cnt} />
                        <SummaryBlock title={t("Шансы субдилеров")} value={summary.level_cnt} />
                    </>
                )}
            </Typography>
        </Typography>
    );
});

interface IProps {
    summary: ILotterySummary | undefined;
}
