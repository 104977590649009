import React, {useEffect, useMemo, useRef, useState} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import moment from "moment";
import _ from "lodash";
import "./style.scss";
// components
import Time from "./time";
import {LoadingIcon} from "../../../ui/loadingIcon";
// store
import Store from "../../../../../stores";

export default observer(function Timer() {
    const {t} = useTranslation();
    const timeout: any = useRef();
    const [state, setState] = useState(<LoadingIcon />);
    const broadcast = useMemo(
        () => Store.lottery?.lottery?.started_at,
        [Store.lottery?.lottery?.started_at]
    );

    useEffect(() => {
        if (!_.isNil(broadcast)) {
            clearTimeout(timeout.current);

            if (!!Store.lottery?.lottery?.started_at) {
                const interval = 1000;
                const eventTime = moment(broadcast);

                const loop = () => {
                    const days = eventTime.diff(moment(), "days") ?? 0;
                    const hours = eventTime.diff(moment(), "hours") - days * 24 ?? 0;
                    const minutes =
                        eventTime.diff(moment(), "minutes") - hours * 60 - days * 24 * 60 ?? 0;

                    if (!days && !hours && !minutes) {
                        setState(
                            <Typography
                                as="div"
                                color="#fff"
                                size={15}
                                className="process-text gold"
                                fontFamily="alumna"
                            >
                                {t("Розыгрыш окончен")}
                            </Typography>
                        );
                    } else {
                        setState(<Time days={days} hours={hours} minutes={minutes} />);
                        timeout.current = setTimeout(loop, interval);
                    }
                };

                loop();
            }
        }

        return () => {
            clearTimeout(timeout.current);
        };
    }, [broadcast]);

    return (
        <Typography as="div" className="bl-lottery-ng-timer">
            <Typography as="div" className="bl-lottery-ng-timer__title">
                <Typography as="div" className="bl-lottery-ng-timer__title__text">
                    Розыгрыш состоится через
                </Typography>
            </Typography>
            <Typography as="div" className="bl-lottery-ng-timer__container">
                {state}
            </Typography>
        </Typography>
    );
});
