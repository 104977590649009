import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import React, {useContext} from "react";
import Cup from "../../../../images/cup_lottery.svg";
import {LayoutContext} from "../../../../core/Contexts";

export default React.memo(function Awards2() {
    const contextLayout = useContext(LayoutContext);
    return (
        <Typography as="div" className="bl-lot-awards">
            <Typography as="div" className="title">
                {_.lte(contextLayout?.width ?? 0, 431) ? "Список призов" : " Призовые места"}
            </Typography>
            <Row>
                <Col span={24}>
                    <Typography as="div" className="block-main-prizes"></Typography>
                </Col>
                <Col span={24}>
                    <Typography as="div" className="block-main-prizes2"></Typography>
                </Col>
                {_.lte(contextLayout?.width ?? 0, 431) ? (
                    <Col span={24}>
                        <Typography as="div" className="title-all-prizes">
                            Призовые места 6 – 5000
                        </Typography>
                    </Col>
                ) : null}
                <Col span={24} className="bl-all-prizes">
                    <Row gutter={[4, 4]}>
                        <Col span={24}>
                            <Typography as="div" className="all-prizes">
                                <img src={Cup} width={24} />С 6 по 10 место: по 50 000
                            </Typography>
                        </Col>

                        <Col span={24}>
                            <Typography as="div" className="all-prizes">
                                <img src={Cup} width={24} />С 11 по 20 место: по 40 000
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography as="div" className="all-prizes">
                                <img src={Cup} width={24} />С 21 по 30 место: по 30 000
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography as="div" className="all-prizes">
                                <img src={Cup} width={24} />С 31 по 50 место: по 20 000
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography as="div" className="all-prizes">
                                <img src={Cup} width={24} />С 51 по 100 место: по 10 000
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography as="div" className="all-prizes">
                                <img src={Cup} width={24} />С 101 по 500 место: по 3 000
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography as="div" className="all-prizes">
                                <img src={Cup} width={24} />С 501 по 1000 место: по 2 500
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography as="div" className="all-prizes">
                                <img src={Cup} width={24} />С 1001 по 2000 место: по 2 000
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography as="div" className="all-prizes">
                                <img src={Cup} width={24} />С 2001 по 3000 место: по 1000
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography as="div" className="all-prizes">
                                <img src={Cup} width={24} />С 3001 по 5000 место: по 500
                            </Typography>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Typography>
    );
});
