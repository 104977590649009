import React from "react";
import {Typography, Button} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {ConfigProvider} from "antd";
import LotteryPhonesService from "../../../../services/LotteryPhonesService";

export default React.memo(function ListPhones({
    loading,
    data,
    isMobile,
    getTable,
    onClick
}: IProps) {
    const {t} = useTranslation();

    return (
        <Typography as="div">
            <ConfigProvider>
                {getTable(isMobile)}
                <div className="lottery-table-btn">
                    <Button
                        type="default"
                        onClick={onClick}
                        loading={loading}
                        disabled={data.length >= LotteryPhonesService.totalCount}
                    >
                        {t("Показать еще")}
                    </Button>
                </div>
            </ConfigProvider>
        </Typography>
    );
});

interface IProps {
    loading: boolean;
    data: any;
    getTable: (isMobile: boolean) => JSX.Element;
    onClick: () => Promise<void>;
    isMobile: boolean;
}
