import React from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from "uuid";
import _ from "lodash";
import "./style.scss";

export default React.memo(function Item({data, suffix}: IProps) {
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-ng-timer-item">
            <Typography as="div" className="bl-ng-timer-item__data">
                {_.chain(["0", ...data.toString().split("")])
                    .takeRight(2)
                    .map((i) => (
                        <Typography key={uuidv4()} as="div" className="bl-ng-timer-item__data__num">
                            <Typography as="div" className="bl-ng-timer-item__data__num__text">
                                {i}
                            </Typography>
                        </Typography>
                    ))
                    .valueOf()}
            </Typography>
            <Typography as="div" className="bl-ng-timer-item__suffix">
                <Typography as="div" className="bl-ng-timer-item__suffix__text">
                    {t(suffix, {count: data})}
                </Typography>
            </Typography>
        </Typography>
    );
});

interface IProps {
    data: number;
    suffix: string;
}
