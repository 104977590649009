// core
import {axiosFetchBuilder} from "../core/Axios";
import {
    ILottery,
    ILotteryRequest,
    ILotterySummary,
    ILotterySummaryRequest
} from "../core/models/Lottery";
// store
import Store from "../stores";

enum Path {
    lottery = "lottery",
    summary = "lottery/summary"
}

class LotteryService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly lottery = Store.lottery;

    static get lotteryInfo() {
        return LotteryService.lottery.lottery;
    }

    static get lotterySummary() {
        return LotteryService.lottery.summary;
    }

    static async getLotteryInfo(request?: ILotteryRequest): Promise<ILottery> {
        const res: ILottery = await axiosFetchBuilder({
            url: Path.lottery,
            params: {
                ...request,
                expand: "awards",
                id: 19
            }
        });
        LotteryService.lottery.lottery = res;
        return res;
    }

    static async getSummary(request?: ILotterySummaryRequest): Promise<ILotterySummary> {
        const res: ILotterySummary = await axiosFetchBuilder({
            url: Path.summary,
            params: {
                ...request
            }
        });
        LotteryService.lottery.summary = res;
        return res;
    }
}

export default LotteryService;
