import {Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import React, {useContext} from "react";
import {LayoutContext} from "../../../../core/Contexts";
import _ from "lodash";

export default React.memo(function SubTitle() {
    const contextLayout = useContext(LayoutContext);
    return (
        <Typography as="div" className="lottery-text-container">
            {_.gte(contextLayout?.width ?? 0, 768) ? (
                <Typography as="div" className="text">
                    {/* {Store.lottery?.lottery?.name
              ? Store.lottery?.lottery?.name
                    .toLocaleLowerCase()
                    .charAt(0)
                    .toUpperCase() +
                Store.lottery?.lottery?.name.toLocaleLowerCase().slice(1)
              : ""} */}
                    {"Для всех пользователей Безлимит"}
                </Typography>
            ) : (
                <Space direction="vertical" size={18}>
                    <Typography as="div" className="text-mobile">
                        {
                            "Участвуют номера, активированные \nс 11 сентября по 24 декабря включительно"
                        }
                    </Typography>
                    <Typography as="div" className="sub-title-mobile">
                        {"Участвуй и выигрывай!"}
                    </Typography>
                </Space>
            )}
        </Typography>
    );
});
