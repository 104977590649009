import React, {useContext} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {LayoutContext} from "../../../../core/Contexts";
import starImg from "../../../../images/arrow-down.png";
import _ from "lodash";

export default React.memo(function ConditionBtn({onOpen}: IProps) {
    const contextLayout = useContext(LayoutContext);

    return (
        <Typography as="div" className="custom-overlay-container">
            <Typography as="div" onClick={() => onOpen("condition")} className="actions-open">
                Подробные условия проведения
                <img
                    src={starImg}
                    alt={"star"}
                    width={_.gte(contextLayout?.width ?? 0, 768) ? 21 : 16}
                />
            </Typography>
        </Typography>
    );
});

interface IProps {
    onOpen: (type: string) => void;
}
