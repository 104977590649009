import React from "react";
import {Typography, Row, Col, Button} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default React.memo(function EmptyNoPassport() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <Typography as="div" className="lottery-table-container">
            <Row gutter={[24, 24]}>
                <Col md={14} xs={24}>
                    <Typography className="lottery-text-register">
                        {t(
                            "Для участия в розыгрыше пройдите\n полную регистрацию в разделе «Профиль»"
                        )}
                    </Typography>
                </Col>
                <Col className="lottery-register" md={10} xs={24}>
                    <Button
                        className="lottery-btn-register"
                        type="primary"
                        onClick={() => navigate("/profile")}
                    >
                        {t("Перейти в Профиль")}
                    </Button>
                </Col>
            </Row>
        </Typography>
    );
});
