import {FormInstance} from "antd";
import _ from "lodash";
import {isMobile} from "react-device-detect";

export function defaultCb(e: any, name: string, formInstance: FormInstance<any>, k: number) {
    const fields = formInstance.getFieldsValue();
    const target = (e as KeyboardEvent).target;
    const value = (target as HTMLInputElement).value;
    const keyCode = e.keyCode === 229 ? value?.charCodeAt(0) : e.keyCode;

    function write(e: any, name: string, formInstance: FormInstance<any>, k: number) {
        const target = (e as KeyboardEvent).target;
        let value = (target as HTMLInputElement).value;
        if (!isMobile) {
            if (e.key == "а" || e.key == "А") {
                value = "A";
            } else if (e.key == "ф" || e.key == "Ф") {
                value = "A";
            } else if (e.key == "в" || e.key == "В") {
                value = "B";
            } else if (e.key == "и" || e.key == "И") {
                value = "B";
            } else if (e.key == "с" || e.key == "С") {
                value = "C";
            } else if (e.key == "т" || e.key == "Т") {
                value = "N";
            } else if (e.key == "н" || e.key == "Н") {
                value = "N";
            }
        }

        if (value) {
            if (e.target.nextElementSibling !== null) {
                e.target.nextElementSibling.focus();
            }
        }
    }

    if ((_.isEqual(keyCode, 8) || _.isEqual(keyCode, 37)) && e.target.previousElementSibling) {
        e.target.previousElementSibling.focus();
    } else if (
        (!e.shiftKey && _.gte(keyCode, 48) && _.lte(keyCode, 57)) ||
        (_.gte(keyCode, 96) && _.lte(keyCode, 105)) ||
        _.isEqual(keyCode, 39) ||
        _.isEqual(keyCode, 70) ||
        _.inRange(e.keyCode, 65, 69) ||
        _.isEqual(keyCode, 89) ||
        _.isEqual(keyCode, 78)
    ) {
        write(e, name, formInstance, k);
    }

    formInstance.setFieldsValue(fields);
}

export function withCharacterCb(e: any, name: string, formInstance: FormInstance<any>, k: number) {
    const fields = formInstance.getFieldsValue();

    const target = (e as KeyboardEvent).target;
    let value = (target as HTMLInputElement).value;
    const keyCode = e.keyCode === 229 ? value?.charCodeAt(0) : e.keyCode;

    function write(e: any, name: string, formInstance: FormInstance<any>, k: number) {
        const target = (e as KeyboardEvent).target;
        let value = (target as HTMLInputElement).value;
        const keyCode = e.keyCode === 229 ? value.charCodeAt(0) : e.keyCode;
        if (!isMobile) {
            if (e.key == "а" || e.key == "А") {
                value = "A";
            } else if (e.key == "ф" || e.key == "Ф") {
                value = "A";
            } else if (e.key == "в" || e.key == "В") {
                value = "B";
            } else if (e.key == "и" || e.key == "И") {
                value = "B";
            } else if (e.key == "с" || e.key == "С") {
                value = "C";
            } else if (e.key == "т" || e.key == "Т") {
                value = "N";
            } else if (e.key == "н" || e.key == "Н") {
                value = "N";
            }
        }

        if (value) {
            if (e.target.nextElementSibling !== null) {
                e.target.nextElementSibling.focus();
            }

            if (formInstance && !_.isEqual(keyCode, 39)) {
                fields[name][k] = value.toUpperCase();
            }
        }
    }

    if ((_.isEqual(keyCode, 8) || _.isEqual(keyCode, 37)) && e.target.previousElementSibling) {
        e.target.previousElementSibling.focus();
    } else if (
        (!e.shiftKey && _.gte(keyCode, 48) && _.lte(keyCode, 57)) ||
        (_.gte(keyCode, 96) && _.lte(keyCode, 105)) ||
        _.isEqual(keyCode, 39) ||
        _.isEqual(keyCode, 70) ||
        _.inRange(keyCode, 65, 69) ||
        _.isEqual(keyCode, 89) ||
        _.isEqual(keyCode, 78)
    ) {
        write(e, name, formInstance, k);
    } else if (_.includes(["A", "B", "C", "N"], value.toUpperCase())) {
        write(e, name, formInstance, k);
    } else {
        if (_.isEqual(keyCode, 16) && _.includes(["A", "B", "C", "N"], value)) {
        } else {
            e.preventDefault();
        }
    }

    formInstance.setFieldsValue(fields);
}

export function toGroupNumbers(
    ref: any,
    name: string,
    formInstance: FormInstance<any>,
    cb?: (e: any, name: string, formInstance: FormInstance<any>, k: number) => void
) {
    if (ref.current) {
        const field = (ref.current as HTMLDivElement).querySelector(".bz-input-group-numbers");
        _.chain(field?.children)
            .forEach((i, k) => {
                if (k === 0 && !_.isEqual(name, "phone_pattern")) {
                    (i as HTMLInputElement).focus();
                }
                (i as HTMLInputElement).disabled = false;
                i.classList.add(`code-item-${k}`);
                i.addEventListener("keyup", function (e: any) {
                    // e.target.focus();
                    cb ? cb(e, name, formInstance, k) : defaultCb(e, name, formInstance, k);
                });
                i.addEventListener("keydown", function (e: any) {
                    e.target.select();

                    if (
                        (_.inRange(e.keyCode, 48, 58) && !e.shiftKey) ||
                        _.isEqual(e.keyCode, 8) ||
                        (_.inRange(e.keyCode, 96, 106) && !e.shiftKey) ||
                        (_.isEqual(e.keyCode, 86) && e.ctrlKey) ||
                        (_.isEqual(e.keyCode, 86) && e.metaKey) ||
                        _.inRange(e.keyCode, 65, 69) ||
                        _.isEqual(e.keyCode, 78) ||
                        _.isEqual(e.keyCode, 70) ||
                        _.isEqual(e.keyCode, 89)
                    ) {
                    } else {
                        e.preventDefault();
                    }
                });
            })
            .valueOf();
    }
}

export function toDisabledGroupNumbers(ref: any) {
    if (ref.current) {
        const field = (ref.current as HTMLDivElement).querySelector(".bz-input-group-numbers");
        _.chain(field?.children)
            .forEach((i, k) => {
                (i as HTMLInputElement).disabled = true;
            })
            .valueOf();
    }
}

export function toDoubleBackspaceCb(
    ref: any,
    name: string,
    formInstance: FormInstance<any>,
    cb?: (e: any, name: string, formInstance: FormInstance<any>, k: number) => void
) {
    if (ref.current) {
        const field = (ref.current as HTMLDivElement).querySelector(".bz-input-group-numbers");
        let prev = formInstance.getFieldValue(name);

        _.chain(field?.children)
            .forEach((i, k) => {
                if (k === 0 && !_.isEqual(name, "phone_pattern")) {
                    (i as HTMLInputElement).focus();
                }
                (i as HTMLInputElement).disabled = false;
                i.classList.add(`code-item-${k}`);
                i.addEventListener("keyup", function (e: any) {
                    if (_.isArray(prev) && _.isEqual(e.keyCode, 8) && prev[k]) {
                        e.preventDefault();
                    } else {
                        cb ? cb(e, name, formInstance, k) : defaultCb(e, name, formInstance, k);
                    }
                    prev = formInstance.getFieldValue(name);
                });
                i.addEventListener("keydown", function (e: any) {
                    if (
                        (_.inRange(e.keyCode, 48, 58) && !e.shiftKey) ||
                        (_.inRange(e.keyCode, 96, 106) && !e.shiftKey) ||
                        (_.isEqual(e.keyCode, 86) && e.ctrlKey) ||
                        (_.isEqual(e.keyCode, 86) && e.metaKey) ||
                        _.inRange(e.keyCode, 65, 69) ||
                        _.isEqual(e.keyCode, 78) ||
                        _.isEqual(e.keyCode, 70) ||
                        _.isEqual(e.keyCode, 89)
                    ) {
                    } else {
                        e.preventDefault();
                    }
                });
            })
            .valueOf();
    }
}
