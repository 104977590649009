import React from 'react';
import { Typography } from '@bezlimit/bezlimit-ui';
import './style.scss';
// components
import Item from './item';

export default React.memo(function Time({ days, hours, minutes }: IProps) {
  return (
    <Typography as="div" className="bl-ng-timer">
      <Item data={days} suffix="count_day_suffix" />
      <Item data={hours} suffix="count_hour_suffix" />
      <Typography as="div" className="bl-ng-timer__dots">
        <Typography as="div" className="bl-ng-timer__dots__text">
          :
        </Typography>
      </Typography>
      <Item data={minutes} suffix="count_minute_suffix" />
    </Typography>
  );
});

interface IProps {
  days: number;
  hours: number;
  minutes: number;
}
