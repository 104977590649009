import {Button, Form, Typography} from "@bezlimit/bezlimit-ui";
import {AutoComplete} from "antd";
import "./style.scss";
import {IReservations, IReservationsResponse} from "../../../../../../../core/models/Reservations";
import npsreser from "../../../../../../../images/npsreser.svg";
import npsreser2 from "../../../../../../../images/npsreser2.png";
import _ from "lodash";
import {isMobile} from "react-device-detect";
import {useContext, useEffect, useState} from "react";
import {LayoutContext} from "../../../../../../../core/Contexts";

const popupClassName = "bl-nps-phone-container";

export default function List({
    npsPhone,
    state,
    reservations,
    loadingNps,
    removed,
    onClick,
    setNpsPhone,
    setEdit
}: IProps) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState(state?.items ?? []);
    const contextLayout = useContext(LayoutContext);

    let updated;

    if (npsPhone || _.get(reservations, "additional_data.nps.phone")) {
        const value: number = +npsPhone! ?? _.get(reservations, "additional_data.nps.phone")!;
        const code = value.toString().substring(1, 3);
        const nums1 = value.toString().substring(3, 6);
        const nums2 = value.toString().substring(6, 8);
        const nums3 = value.toString().substring(8, 10);
        updated = `+7 9${code} ${nums1} ${nums2}${nums3}`;
    }

    const [npsPhoneDefault, setNpsPhoneDefault] = useState<string | undefined>(updated);

    const onSelect = (value: any) => {
        const updated = _.replace(_.replace(value, "+7", ""), /\s/g, "");
        setNpsPhone(updated);
        setEdit(false);
    };

    const onSearch = (value: string) => {
        const updated = _.replace(_.replace(value, "+7", ""), /\s/g, "").substring(0, 10);
        setOptions(state?.items ?? []);

        if (_.gt(_.size(updated), 1) && _.lte(_.size(updated), 10)) {
            console.log(updated);

            setOptions(
                _.chain(state?.items)
                    .filter(({phone_number}) => _.startsWith(phone_number.toString(), updated))
                    .valueOf()
            );
        }
    };

    useEffect(() => {
        setOptions(state?.items ?? []);
    }, [state]);

    useEffect(() => {
        if (!open) {
            setOptions(state?.items ?? []);
        }
    }, [open]);

    useEffect(() => {
        if (removed) {
            form.setFieldValue("npsPhone", undefined);
            setNpsPhoneDefault(undefined);
        }
    }, [removed]);

    return (
        <Typography as="div" className="bl-nps-reservation-list">
            {!state?.items.length || removed ? (
                <Button icon={<img src={npsreser} alt="" />} onClick={onClick} loading={loadingNps}>
                    <Typography as="div" className="inner">
                        {_.gt(contextLayout?.width, 992) ? (
                            "Не плати за связь! Укажи связанный номер для участия в акции"
                        ) : (
                            <Typography as="div" className="mob-container">
                                <Typography as="div" className="title">
                                    Не плати за связь!
                                </Typography>
                                <Typography as="div" className="text">
                                    Укажи связанный номер для участия в акции
                                </Typography>
                            </Typography>
                        )}
                    </Typography>
                </Button>
            ) : (
                <>
                    <Typography as="div" className="nps-dropdown">
                        <Typography as="div" className="nps-dropdown-container">
                            <img src={npsreser2} alt="" />
                            <Form
                                form={form}
                                layout="horizontal"
                                autoComplete="off"
                                initialValues={{
                                    npsPhone: npsPhoneDefault
                                }}
                            >
                                <svg
                                    className={open ? "is-open" : ""}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="6"
                                    viewBox="0 0 10 6"
                                    fill="none"
                                >
                                    <path
                                        d="M0.856934 1L4.85693 5L8.85693 1"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <Form.Item
                                    name="npsPhone"
                                    normalize={(value) => {
                                        let clearedValue: string;
                                        const updated = /^8([\d+]{10})$/gim.exec(
                                            _.replace(value, /(\+7|\s|\-|\(|\)|\D)/gim, "")
                                        );

                                        if (updated && updated[1]) {
                                            clearedValue = _.replace(
                                                updated[1],
                                                /(\+7|\s|\-|\(|\)|\D)/gim,
                                                ""
                                            );
                                        } else {
                                            clearedValue = _.replace(
                                                value,
                                                /(\+7|\s|\-|\(|\)|\D)/gim,
                                                ""
                                            );
                                        }

                                        if (!_.size(_.trim(clearedValue))) {
                                            return "";
                                        } else {
                                            if (isMobile && !_.isEqual(clearedValue[0], "9")) {
                                                clearedValue = "9";
                                            }

                                            const code = clearedValue.substring(1, 3);
                                            const nums1 = clearedValue.substring(3, 6);
                                            const nums2 = clearedValue.substring(6, 8);
                                            const nums3 = clearedValue.substring(8, 10);

                                            return `+7 9${code}${nums1 ? ` ${nums1}` : ""}${
                                                nums2 ? ` ${nums2}` : ""
                                            }${_.gt(_.size(clearedValue), 8) ? `${nums3}` : ""}`;
                                        }
                                    }}
                                >
                                    <AutoComplete
                                        autoFocus={true}
                                        popupClassName={popupClassName}
                                        onSelect={onSelect}
                                        onDropdownVisibleChange={(open) => {
                                            setOpen(open);
                                        }}
                                        notFoundContent={"Номер не найден"}
                                        options={_.chain(options)
                                            .map(({phone_number: value}) => {
                                                const code = value.toString().substring(1, 3);
                                                const nums1 = value.toString().substring(3, 6);
                                                const nums2 = value.toString().substring(6, 8);
                                                const nums3 = value.toString().substring(8, 10);

                                                return {
                                                    label: value,
                                                    value: `+7 9${code} ${nums1} ${nums2}${nums3}`
                                                };
                                            })
                                            .valueOf()}
                                        onSearch={onSearch}
                                        placeholder="+7 965 299 99 99 "
                                    />
                                </Form.Item>
                            </Form>
                        </Typography>
                        <Typography as="div" className="nps-dropdown-text">
                            Введите номер или выберите
                        </Typography>
                    </Typography>
                </>
            )}
        </Typography>
    );
}

interface IProps {
    npsPhone: string | undefined;
    reservations: IReservations | undefined;
    loadingNps: boolean;
    state: IReservationsResponse | undefined;
    removed: boolean;
    onClick: () => void;
    setNpsPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
}
