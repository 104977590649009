import {Button, Drawer, Typography} from "@bezlimit/bezlimit-ui";
import React from "react";

export default React.memo(function Modal({
    isDescription,
    drawerContent,
    onClose,
    open,
    cssClass
}: any) {
    return (
        <Drawer
            title={null}
            footer={null}
            placement="right"
            onClose={onClose}
            open={open}
            width={isDescription ? 856 : 740}
            className={`bl-lottery-block2-drawer ${cssClass ? cssClass : ""}`}
            maskClosable={true}
            destroyOnClose={true}
            closeIcon={
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                >
                    <path
                        d="M29 1L15.0458 15M15.0458 15L1 29M15.0458 15L1 1M15.0458 15L29 29"
                        stroke="#E5E5E5"
                        strokeWidth="2"
                        strokeLinecap="round"
                    />
                </svg>
            }
        >
            {drawerContent}
            <Typography as="div" className="actions">
                <Button type="primary" onClick={onClose}>
                    Закрыть
                </Button>
            </Typography>
        </Drawer>
    );
});
