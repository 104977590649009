import {Typography} from "@bezlimit/bezlimit-ui";

export const SummaryBlock = ({title, value}: IProps): JSX.Element => {
    return (
        <Typography as="div" className="all-summury">
            <Typography className="lottery-summary" as="div">
                <Typography className="summary-value gold">{value}</Typography>
                <div className="conteiner-summary-title">
                    <Typography className="summary-title">{title}</Typography>
                </div>
            </Typography>
        </Typography>
    );
};

interface IProps {
    title: string;
    value: number;
}
